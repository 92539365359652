import React, { useRef, useState, useEffect } from "react";
import FeaturedExpert from "./featured.js";
import { useStaticQuery, graphql } from "gatsby";
import { Row, Col } from "react-bootstrap";
import RadioButtons from "./radiobuttons.js";
import Controls from "./controls.js";
import Results from "./result.js";
import "./assets/css/style.scss";
import { Link } from "gatsby";
import { fadeIn, slideInLeft } from "react-animations";
import isAuthed from "../../api/utils/isAuthed";
import {
  listExperts,
  followExpert,
  unfollowExpert,
} from "../../api/actions/experts";
import { listSpecialities } from "../../api/actions/specialities";
import { allCategories } from "../../api/actions/allcategories";
import { getFollowedExperts } from "../../api/actions/me";
import Radium, { StyleRoot } from "radium";
import { debounce } from "lodash";
import Fuse from "fuse.js";

export default function Expert() {
  const expertpagecontainer = useRef();
  const signupsection = useRef();
  const openmobileoptions = () => {
    expertpagecontainer.current.classList.toggle("open");
  };
  const styles = {
    fadeIn: {
      animation: "x 0.5s",
      animationName: Radium.keyframes(fadeIn, "fadeIn"),
    },
    slideInLeft: {
      animation: "x 0.5s",
      animationName: Radium.keyframes(slideInLeft, "slideInLeft"),
    },
  };
  const data = useStaticQuery(graphql`
    {
      allCosmicjsExperts {
        edges {
          node {
            metadata {
              heading_expert
              sub_heading_expert
            }
            slug
          }
        }
      }
      allCosmicjsFeatureProfiles {
        nodes {
          metadata {
            designation
            description
            education
            expert
            location
            media {
              url
            }
            name
          }
        }
      }
    }
  `);

  var content_featured;
  var content_expert_header;
  typeof window !== "undefined" &&
    window.addEventListener("scroll", function () {
      try {
        if (window.pageYOffset > 852) {
          signupsection.current.classList.add("addimgcont");
        } else {
          signupsection.current.classList.remove("addimgcont");
        }
        if (window.pageYOffset > 5228) {
          signupsection.current.classList.remove("addimgcont");
        }
      } catch (e) { }
    });

  data.allCosmicjsExperts.edges.map((x, y) => {
    switch (x.node.slug) {
      case "header-expert":
        content_expert_header = x.node.metadata;
        break;
      default:
        break;
    }
  });

  const [allExperts, setAllExperts] = useState([]);
  useEffect(() => {
    async function fetchExperts() {
      const { data: experts } = await listExperts();
      setAllExperts(experts);

      if (isAuthed()) {
        const { data: followedExperts } = await getFollowedExperts();
        followedExperts.forEach(({ id }) => {
          experts.some((expert) => {
            if (expert.id === id) {
              expert.follower = true;
              return true;
            }
          });
        });
        if (followedExperts.length > 0) setAllExperts(experts);
      }
    }

    fetchExperts();
  }, []);

  const [subtopics, setSubtopics] = useState([]);
  useEffect(() => {
    allCategories().then((subtopics) => {
      if (subtopics) {
        subtopics.unshift({ id: "all", description: "All" });
        setSubtopics(subtopics);
      }
    });
  }, []);

  const [specialities, setSpecialities] = useState([]);
  useEffect(() => {
    listSpecialities().then((response) => {
      if (response) {
        response.data.unshift({ id: "all", description: "All" });
        setSpecialities(response.data);
      }
    });
  }, []);

  const [filters, setFilters] = useState({
    subtopicId: "all",
    specialityId: "all",
  });
  const [search, setSearch] = useState("");
  const setSearchDebounced = debounce(setSearch, 200);

  const [fuse, setFuse] = useState();
  useEffect(() => {
    setFuse(
      new Fuse(allExperts, {
        keys: ["city", "country", "first_name", "last_name"],
      })
    );
  }, [allExperts.length]);

  const [filteredExperts, setFilteredExperts] = useState([]);
  useEffect(() => {
    let experts = search
      ? fuse.search(search).map((result) => result.item)
      : allExperts;
    if (filters.subtopicId !== "all" || filters.specialityId !== "all") {
      experts = experts.filter((expert) => {
        // TODO: generate lookup tables because this is slow enough to cause lag
        const hasSubtopic =
          filters.subtopicId === "all" ||
          expert.subtopics.some(({ id }) => id === filters.subtopicId);
        const hasSpeciality =
          filters.specialityId === "all" ||
          expert.specialities.some(({ id }) => id === filters.specialityId);
        return hasSubtopic && hasSpeciality;
      });
    }
    experts = experts.slice(0, 20);
    setFilteredExperts(experts);

    // get answer counts for each expert
    // TODO: get this working. currently it creates a race conditon with the
    // follow data, and by the time we finish fetching answer counts,
    // filteredExperts is stale
    //
    // Promise.all(
    //   filteredExperts.map(async (expert) => {
    //     if (!expert.answers_count) {
    //       const response = await getAnswersByExpert(expert.id);
    //
    //       return { ...expert, answers_count: response.data.answers_count };
    //     } else {
    //       return expert;
    //     }
    //   );
    // }).then(setFilteredExperts);
  }, [allExperts.length, search, filters.subtopicId, filters.specialityId]);

  async function handleToggleFollow(shouldFollow, expertId) {
    const response = shouldFollow
      ? await followExpert(expertId)
      : await unfollowExpert(expertId);

    if (response && response.status === 200) {
      setAllExperts((experts) => {
        experts.find((expert) => {
          if (expert.id === expertId) {
            expert.follower = shouldFollow;
            return true;
          }
        });
        return [...experts];
      });
    }
  }

  return (
    <div className="experts" ref={expertpagecontainer} id="expertpage">
      {/* <Header
        heading_expert={content_expert_header.heading_expert}
        sub_heading_expert={content_expert_header.sub_heading_expert}
      /> */}

      <FeaturedExpert content={data.allCosmicjsFeatureProfiles} />
      <div className="mainExpert">
        <div className="content">
          <Row>
            <Col lg={9} sm={12} md={12}>
              <h2>Find Your Fertility Partner</h2>
              <Controls
                specialities={specialities}
                onSelectSpeciality={(specialityId) =>
                  setFilters({ ...filters, specialityId })
                }
                onChangeSearch={setSearchDebounced}
                openmobileoptions={openmobileoptions}
              />
              <Results
                content={filteredExperts}
                onToggleFollow={handleToggleFollow}
              />
            </Col>
            <Col sm={12} md={12} lg={3} className="hidden-xs">
              {/*}    <h2>Filter</h2>
              <RadioButtons
                title="Sort by Subtopic"
                content={subtopics}
                onChange={(subtopicId) =>
                  setFilters({ ...filters, subtopicId })
                }
              />*/}
              <div className="CreateAccount expert-page" ref={signupsection}>
                <h1>Want to join our Fertility Network?</h1>
                <p>If you’re board certified, we’d love to hear from you</p>
                <button>
                  <Link to="expert-signup">Connect</Link>
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <StyleRoot>
        <div className="mobilefilters mainExpert">
          <div
            className="backdrop"
            style={styles.fadeIn}
            onClick={openmobileoptions}
          ></div>
          <div className="halfcontainer" style={styles.slideInLeft}>
            <div className="close" onClick={openmobileoptions}>
              ×
            </div>
            <div className="contentinner">
              <h3>Sort by</h3>
              <RadioButtons
                title="Subtopic"
                content={subtopics}
                onChange={(subtopicId) =>
                  setFilters({ ...filters, subtopicId })
                }
              />

              <RadioButtons
                check="check"
                title="Partner Type"
                content={specialities}
                onChange={(specialityId) =>
                  setFilters({ ...filters, specialityId })
                }
              />
            </div>
            <div className="apply">
              <button type="button" onClick={openmobileoptions}>
                Apply
              </button>
            </div>
          </div>
        </div>
      </StyleRoot>
    </div>
  );
}
